<template>
  <nav class="navigation">
    <ul class="gnb">
      <li 
        v-for="item in items" 
        :key="item.path" 
        class="gnb-item" 
        :class="{ 'active': activeMenu === item.name }" 
        @mouseover="handleHover(item.name)" 
        @mouseleave="handleLeave"
      >
        <template v-if="item.children">
          <p class="top-level">
            <i><img :src="item.icon" alt="" /></i>
            <span>{{ item.name }}</span>
          </p>
          <ul :style="{ height: activeMenu === item.name ? heights[item.name] + 'px' : '0' }">
            <li v-for="child in item.children" :key="child.path">
              <router-link :to="child.path" class="nested block">{{ child.name }}</router-link>
            </li>
          </ul>
        </template>
        <template v-else>
          <router-link :to="item.path" class="top-level">
            <i><img :src="item.icon" alt="" /></i>
            <span>{{ item.name }}</span>
          </router-link>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import bookIcon from '@/assets/cms/img/icons/book-open-reader-solid.svg';
import awardIcon from '@/assets/cms/img/icons/award-solid.svg';
import addressIcon from '@/assets/cms/img/icons/address-book-solid.svg';
import commentIcon from '@/assets/cms/img/icons/comment-dots-solid.svg';
import sackIcon from '@/assets/cms/img/icons/sack-dollar-solid.svg';
import chartIcon from '@/assets/cms/img/icons/chart-simple-solid.svg';
import diagramIcon from '@/assets/cms/img/icons/diagram-project-solid.svg';

const items = ref ([
  {
    name: '교육과정관리',
    icon: bookIcon,
    children: [
      { path: '/lms/course', name: '교과목 목록' },
      { path: '/lms/list', name: '수강 목록' }
    ]
  },
  {
    name: '디지털배지',
    icon: awardIcon,
    children: [
      { path: '/lms/badge', name: '발급 내역' },
      { path: '/lms/b-apply', name: '발급 신청' }
    ]
  },
  { 
    name: '회원 관리',
    path: '/lms/member',    
    icon: addressIcon,    
  },
  {
    name: '커뮤니티 관리',
    icon: commentIcon,
    children: [
      { path: '/lms/notice', name: '공지사항' },
      { path: '/lms/qna', name: 'Q&A' },
      { path: '/lms/dataroom', name: '자료실' }
    ]
  },
  { 
    name: '사업비 관리',
    path: '/cms/pms',    
    icon: sackIcon,
  },
  { 
    name: '성과 관리',
    path: '/cms/performance',    
    icon: chartIcon,
  },
  {
    name: '로그 관리',
    icon: diagramIcon,
    children: [
      { path: '/lms/system', name: '접근 이력' },
      { path: '/lms/statistics', name: '로그인 통계' }
    ]
  },
]);

const activeMenu = ref('');
const heights = ref({});

const handleHover = (name) => {
  activeMenu.value = name;
};

const handleLeave = () => {
  activeMenu.value = '';
};

const calculateHeights = () => {
  const gnbItems = document.querySelectorAll('.gnb-item');
  gnbItems.forEach(item => {
    const nestedList = item.querySelector('ul');
    if (nestedList) {
      const liElements = nestedList.querySelectorAll('li');
      let totalHeight = 0;
      liElements.forEach(li => {
        totalHeight += li.offsetHeight;
      });
      heights.value[item.querySelector('.top-level').textContent.trim()] = totalHeight;
    }
  });
};

onMounted(() => {
  calculateHeights();
});
</script>

<style lang="scss" scoped>
.cms {
  .navigation {    
    .gnb {
      .gnb-item {        
        cursor: pointer;
        @include transition($time:.3s);
        .top-level {
          display: flex;
          align-items: flex-start;
          gap: 1.8rem;
          padding: 1.8rem 1.8rem 1.8rem 2.2rem;
          line-height: 2rem;
          letter-spacing: 0;          
          i {
            display: flex;
            flex: 0 0 1.5rem;
            height: 2rem;
            align-items: center;
            img {
              width: 100%;
            }
          }
          span {
            flex: 1; 
          }
        }
        ul {
          overflow: hidden;
          @include transition($time:.3s);
          li {
            .nested {
              padding: 1.2rem 1.2rem 1.2rem 5.5rem;
              font-size: 1.4rem;
              &:hover {
                background: rgba(0, 0, 0, .2);
              }
            }
          }          
        }
        &.active {
          background: rgba(0, 0, 0, .2);
          .top-level {
            
          }
          ul {
            height: 200px;
          }
        }       
      }    
    }
  }
  .mini {
    .navigation {    
      .gnb {
        .gnb-item {        
          cursor: pointer;
          .top-level {
            i {
              img {
              }
            }
          }
          ul {
            li {
              .nested {
                &:hover {
                }
              }
            }          
          }
          &.active {
            .top-level {

            }
            ul {
            }
          }       
        }    
      }
    }
  }
}
</style>