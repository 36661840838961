<template>
  <aside :class="{ mini : isSimple }">
    <div class="container grid fix">
      <div class="ta"> 
        <div class="flex__e">
          <button class="slide-btn" @click="toggleShow">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="18" viewBox="0 0 26 14">
              <path d="M0 0h26v1H0z M0 7h26v1H0z M0 14h26v1H0z" fill="currentColor"/>
            </svg>
          </button>
        </div>
        <div class="logo flex__c">
          <router-link to="/lms/" class="block">
            <img src="@/assets/cms/img/logo-v2-wh.png" alt="NCCOSS NEXT-GEN. COMMUNICATION CONVERGENCE AND OPEN SHARING SYSTEM">
          </router-link>
        </div>
      </div>
      <div class="ea grid">
        <GnbComp />
        <FooterComp />
      </div>      
    </div>
  </aside>  
</template>

<script setup>
import { ref } from 'vue';
import GnbComp from '@/components/cms/GnbComp';
import FooterComp from '@/components/cms/FooterComp';

const isSimple = ref(false);

function toggleShow(){
  isSimple.value = !isSimple.value;
}
</script>

<style lang="scss" scoped>
.cms {
  aside {  
    .container {
      width: $asideW;
      top: 0;    
      bottom: 0;
      left: 0;
      grid-template-rows: 24rem auto;  
      color: rgba(255, 255, 255, .9);
      background: linear-gradient(180deg, rgba(61,100,251,1) 0%, rgba(54,92,240,1) 50%, rgb(13, 46, 177) 100%);
      .ta {
        padding: .8rem 1rem;
        .slide-btn {
          width: 4.4rem;
          height: 4.4rem;
          opacity: .7;
          border-radius: 50%;
          @include trans();
          &:hover {
            background: rgba(255, 255, 255, .1);
          }
        }
        .logo {
          padding: 1rem 0;
          img {
            width: 100%;
            max-width: 86px;
          }
        }
      }
      .ea {
        grid-template-rows: 1fr;
        gap: 5rem;
        overflow: auto;
        overscroll-behavior: contain;
        overscroll-behavior-y: contain;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
</style>