<template>
  <div class="cms wrap" @scroll="handleScroll">
    <div class="grid rel">
      <AsideComp />
      <div class="content" ref="contentElement" @scroll="handleScroll">
        <div class="content-inner">
          <HeaderComp />
          <main>
            <router-view class="content-container" />            
          </main>
        </div>
        <button type="button" class="fix top-btn" :class="{ show: isTopBtnVisible }" @click="scrollToTop">
          <span class="material-symbols-outlined">keyboard_arrow_up</span>
        </button>
      </div>
    </div>
  </div> <!--// wrap end -->
</template>

<script setup>
import { ref, onMounted } from 'vue';
import '@/assets/cms/style/css/reset.min.css'
import '@/assets/cms/style/css/common.css'
import '@/assets/cms/style/css/style.css'

import AsideComp from '@/components/cms/AsideComp.vue'
import HeaderComp from '@/components/cms/HeaderComp.vue'

const contentElement = ref(null);
const isTopBtnVisible = ref(false);

const handleScroll = () => {
  if (contentElement.value) {
    const scrollPosition = contentElement.value.scrollTop;
    isTopBtnVisible.value = scrollPosition > 200;
  }
};

const scrollToTop = () => {
  if (contentElement.value) {
    contentElement.value.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
};

onMounted(() => {
  if (contentElement.value) {
    contentElement.value.addEventListener('scroll', handleScroll);
  }
});
</script>

<style lang="scss" scoped>
.cms {
  &.wrap {
    > .grid {
      height: 100%;
      grid-template-columns: $asideW auto;
    }
    .content {
      overflow: auto;
      background-color: #f1f4ff;
      .content-inner {
        min-width: $minWidth;        
        main {
          padding: 5rem 3rem;
          .content-container {
            width: 100%;
            max-width: 160rem;
            margin:0 auto;
          }
        }        
      }
      .top-btn {
        $size: 4rem;
        width: $size;
        height: $size;
        bottom: 3rem;
        right: 3rem;
        color: $text;
        background: linear-gradient(122deg, #e9e9e9, $wh 97%);
        box-shadow: inset .1rem .1rem .1rem 0 $wh, inset -.1rem -.1rem .1rem 0 #c7c7ca;
        border-radius: 1rem;
        opacity: 0;
        transform: translateY($size);
        @include trans();
        &:active {
          background: linear-gradient(to bottom, #e3e9f0, #eceff3);
          box-shadow: inset .1rem .1rem .1rem 0 #c7c7ca, inset -.1rem -.1rem .1rem 0 $wh;
          color: $text-muted;
          @include trans($time:0s);
        }
        &.show {
          opacity: 1;
          transform: translateY(0);          
        }
      }
    }
  }
}
</style>